<template>
  <div>
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img src="../assets/pic/1.png" alt="" />
        </div>
        <div class="swiper-slide">
          <img src="../assets/pic/2.png" alt="" />
        </div>
        <div class="swiper-slide">
          <img src="../assets/pic/3.png" alt="" />
        </div>
        <div class="swiper-slide">
          <img src="../assets/pic/4.png" alt="" />
        </div>
        <div class="swiper-slide">
          <img src="../assets/pic/5.png" alt="" />
        </div>
        <div class="swiper-slide">
          <img src="../assets/pic/6.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 如果需要分页器 -->
    <div class="pagination">
      <div class="items" :class="[active_index == 1 ? 'active' : '']"></div>
      <div class="items" :class="[active_index == 2 ? 'active' : '']"></div>
      <div class="items" :class="[active_index >= 3 ? 'active' : '']"></div>
    </div>
  </div>
</template>

<script>
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from 'swiper'

// import { Swiper, SwiperSlide } from 'swiper/vue'
// import 'swiper/css'
export default {
  data() {
    return {
      active_index: 1
    }
  },
  mounted() {
    let that = this
    new Swiper('.swiper-container', {
      // direction: 'vertical', // 垂直切换选项
      mousewheel: true, //滚轮
      effect: 'coverflow',
      slidesPerView: 2,
      centeredSlides: true,
      autoplay: {
        //自动开始
        delay: 2500, //时间间隔
        disableOnInteraction: false //*手动操作轮播图后不会暂停*
      },
      loop: true, // 循环模式选项
      on: {
        transitionEnd: function () {
          that.active_index = this.activeIndex - 1
        }
      }
    })
  },
  methods: {}
}
</script>

<style lang="less">
.active {
  background: #bdbdbd;
  opacity: 0.16;
  width: 20px;
  border-radius: 20px;
}
.swiper-container {
  .swiper-wrapper {
    .swiper-slide {
      display: flex;
      img {
        width: 45vw;
        height: 48.58vh;
      }
    }
  }
}
.pagination {
  margin-top: 2.4vh;
  display: flex;
  justify-content: center;
  .items {
    width: 15px;
    height: 3px;
    background: #d8d8d8;
    border-radius: 1px;
    position: relative;
    margin: 0 2px;
  }
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  background: transparent;
  opacity: 0 !important;
}
</style>
