import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home-page.vue'
import notFind from '../views/notFind.vue'
import List from '../views/goods-list.vue'
// import ai from '../views/motu-ai.vue'
import shan from '../views/moge-shan.vue'
import chaK from '../views/mage-chake.vue'
import AiShare from '../views/AiShare.vue'
import taikula from '../views/moge-tkl.vue'
import goodsList from '@/views/goods-list'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    meta: {
      title: '木鱼星球',
      icon: 'https://gjc-oss.oss-cn-nanjing.aliyuncs.com/moge_h5/favicon.ico'
    }
  },
  {
    path: '*',
    component: notFind
  },
  {
    path: '/list',
    component: List
  },
  // {
  //   path: '/ai',
  //   component: ai,
  //   meta: {
  //     title: '魔图AI绘画',
  //     icon: 'https://gjc-oss.oss-cn-nanjing.aliyuncs.com/moge_h5/ai.ico'
  //   }
  // },
  {
    path: '/shan',
    component: shan,
    meta: {
      title: '闪闪弹幕',
      icon: 'https://gjc-oss.oss-cn-nanjing.aliyuncs.com/moge_h5/favicon.ico'
    }
  },
  {
    path: '/chaK',
    component: chaK,
    meta: {
      title: '茶克浏览器',
      icon: 'https://gjc-oss.oss-cn-nanjing.aliyuncs.com/moge_h5/01.ico'
    }
  },
  {
    path: '/chaK/AiShare',
    component: AiShare,
    meta: {
      title: '茶克浏览器',
      icon: 'https://gjc-oss.oss-cn-nanjing.aliyuncs.com/moge_h5/01.ico'
    }
  },
  {
    path: '/taikula',
    component: taikula,
    meta: {
      title: '泰库拉浏览器',
      icon: 'https://gjc-oss.oss-cn-nanjing.aliyuncs.com/takul/favicon.ico'
    }
  },
  {
    path: '/goodsList',
    component: goodsList,
    meta: {
      title: '更多应用'
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
