import { render, staticRenderFns } from "./moge-shan.vue?vue&type=template&id=45fa9dfd&scoped=true&"
import script from "./moge-shan.vue?vue&type=script&lang=js&"
export * from "./moge-shan.vue?vue&type=script&lang=js&"
import style0 from "./moge-shan.vue?vue&type=style&index=0&id=45fa9dfd&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45fa9dfd",
  null
  
)

export default component.exports