<template>
  <div class="container">
    <div @click="goMore" class="more">
      <div class="more_item">更多</div>
    </div>

    <div class="box_nav">
      <img src="../assets/pic/chak/01.png" alt="" />
      <div class="text1">茶克浏览器</div>
    </div>
    <div class="box1_nav">一次搜索多种结果，纯净体验</div>
    <div>
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="../assets/pic/chak/03.jpg" alt="" />
          </div>
          <div class="swiper-slide">
            <img src="../assets/pic/chak/05.jpg" alt="" />
          </div>
          <div class="swiper-slide">
            <img src="../assets/pic/chak/02.jpg" alt="" />
          </div>
          <div class="swiper-slide">
            <img src="../assets/pic/chak/04.jpg" alt="" />
          </div>
          <div class="swiper-slide">
            <img src="../assets/pic/chak/06.jpg" alt="" />
          </div>
          <div class="swiper-slide">
            <img src="../assets/pic/chak/07.jpg" alt="" />
          </div>
        </div>
      </div>
      <!-- 如果需要分页器 -->
      <div class="pagination">
        <div class="items" :class="[active_index == 1 ? 'active' : '']"></div>
        <div class="items" :class="[active_index == 2 ? 'active' : '']"></div>
        <div class="items" :class="[active_index >= 3 ? 'active' : '']"></div>
      </div>
    </div>
    <div class="box_detail">
      <div class="text2">合肥墨格网络科技有限公司&nbsp;茶克浏览器</div>
      <div class="text3">
        <a
          style="color: #68d3ff"
          href="http://admin.pxxpxxpxx.com/prod-api/profile/agreement/2023/04/25/agreement_20230425113411A001.html"
          >用户协议
        </a>
        &nbsp;|&nbsp;
        <a
          style="color: #68d3ff"
          href="http://admin.pxxpxxpxx.com/prod-api/profile/agreement/2023/04/25/agreement_20230425113446A002.html"
          >隐私政策</a
        >
      </div>
    </div>
    <div class="button" @click="downLoad">立即下载</div>

    <div class="mask" v-show="open" @click="close">
      <div class="arrowhead">
        <img src="../assets/pic/8.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from 'swiper'
export default {
  data() {
    return {
      open: false,
      active_index: 1
    }
  },
  mounted() {
    let that = this
    new Swiper('.swiper-container', {
      // direction: 'vertical', // 垂直切换选项
      mousewheel: true, //滚轮
      effect: 'coverflow',
      slidesPerView: 2,
      centeredSlides: true,
      autoplay: {
        //自动开始
        delay: 2500, //时间间隔
        disableOnInteraction: false //*手动操作轮播图后不会暂停*
      },
      loop: true, // 循环模式选项
      on: {
        transitionEnd: function () {
          that.active_index = this.activeIndex - 1
        }
      }
    })
  },
  methods: {
    goMore() {
      this.$router.push('/goodsList')
    },
    close() {
      this.open = false
    },
    isWeiXi() {
      var ua = navigator.userAgent.toLowerCase()
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        console.log('微信')
        this.open = true
      } else {
        console.log('非微信')
        // this.getUrl()
      }
    },
    downLoad() {
      let webLog = {}
      let userAgent = navigator.userAgent
      // 获取微信版本
      let m1 = userAgent.match(/MicroMessenger.*?(?= )/)
      if (m1 && m1.length > 0) {
        webLog.wechat = m1[0]
      }
      // 苹果手机
      if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {
        // 获取设备名
        this.isWeiXi()
        if (userAgent.includes('iPad')) {
          webLog.device = 'iPad'
        } else {
          webLog.device = 'iPhone'
        }
        // 获取操作系统版本
        m1 = userAgent.match(/iPhone OS .*?(?= )/)
        if (m1 && m1.length > 0) {
          webLog.system = m1[0]
          console.log(webLog.system)
        }
        return
      }
      // 安卓手机
      if (userAgent.includes('Android')) {
        // 获取设备名
        m1 = userAgent.match(/Android.*; ?(.*(?= Build))/)
        var ua = navigator.userAgent.toLowerCase()
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
          console.log('微信')
          this.open = true
        } else {
          console.log('非微信')
          window.open(
            'https://gjc-oss.oss-cn-nanjing.aliyuncs.com/chak/__UNI__D1948B0__20230426170054.apk' //下载包
          )
        }
      }
    }
    // getUrl() {
    //   axios
    //     .get('/mini/url/getUrlScheme?appId=wx5fad34674fc07987&path=/pages/index/index') //微信小程序
    //     .then((res) => {
    //       if (res.data.code == 0) {
    //         console.log(res.data.message)
    //         location.href = res.data.message
    //       }
    //     })
    // }
  }
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
a:link {
  color: #68d3ff;
  text-decoration: none;
}
.container {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .more {
    position: absolute;
    right: 0;
    margin-top: 1.6vh;
    width: 8.6vw;
    height: 55px;
    background: #b8b8b8;
    border-radius: 6px 0 0 6px;
    display: flex;
    align-items: center;
    .more_item {
      margin-left: 6px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .box_nav {
    display: flex;
    justify-content: center;
    margin-top: 6.644vh;

    img {
      width: 11.2vw;
      height: auto;
      border-radius: 2.933vw;
      margin-right: 2.133vw;
    }
    .text1 {
      margin: auto 0;
      font-size: 5.333vw;
      font-weight: bold;
      color: #000000;
    }
  }
  .box1_nav {
    margin-top: 1.649vh;
    display: flex;
    justify-content: center;
    font-weight: 400;
    color: #b1b1b1;
    font-size: 3.733vw;
  }
  .swiper {
    // display: flex;
    justify-content: center;
    margin-top: 3.898vh;
  }
  .box_detail {
    margin-top: 2.4vh;
    font-size: 2.933vw;
    font-weight: 400;
    height: 4.198vh;
    .text2 {
      display: flex;
      justify-content: center;
      color: #a7a7a7;
    }
    .text3 {
      display: flex;
      justify-content: center;
      color: #68d3ff;
      margin-top: 0.5vh;
    }
  }
  .button {
    margin: 0 auto;
    margin-top: 4.348vh;
    width: 74.93vw;
    height: 7.0465vh;
    background-color: rgba(0, 180, 255, 1);
    border-radius: 2.667vw;
    font-size: 4.533vw;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    line-height: 7.0465vh;
  }
  .mask {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1000;
    font-size: 2.933vw;
    font-weight: 400;
    color: #fdfcfc;
    .arrowhead {
      display: flex;
      justify-content: flex-end;
      margin-top: 4vw;
      margin-right: 8vw;
      img {
        width: 18.67vw;
        height: 12vh;
        margin-bottom: 2.933vw;
      }
    }
    P {
      display: flex;
      justify-content: flex-end;
      margin-right: 5.866vw;
    }
  }
  .active {
    background: #bdbdbd;
    opacity: 0.16;
    width: 20px;
    border-radius: 20px;
  }
  .swiper-container {
    margin-top: 26px;
    .swiper-wrapper {
      .swiper-slide {
        display: flex;
        img {
          width: 45vw;
          height: 48.58vh;
        }
      }
    }
  }
  .pagination {
    margin-top: 2.4vh;
    display: flex;
    justify-content: center;
    .items {
      width: 15px;
      height: 3px;
      background: #d8d8d8;
      border-radius: 1px;
      position: relative;
      margin: 0 2px;
    }
  }

  .swiper-slide-shadow-left,
  .swiper-slide-shadow-right {
    background: transparent;
    opacity: 0 !important;
  }
}
</style>
