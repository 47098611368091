<template>
  <div>
    <h1>404 111</h1>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less"></style>
