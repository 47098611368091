import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '../src/assets/css/icon.css'
//配置axios,设置默认请求接口地址
import axios from 'axios'
axios.defaults.baseURL = 'https://app.pxxpxxpxx.com'
Vue.prototype.$axios = axios

Vue.config.productionTip = false

router.beforeEach((to, form, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
    document.querySelector('link[rel="icon"]').setAttribute('href', to.meta.icon)
  }
  next()
})
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
