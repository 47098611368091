<template>
  <div class="container">
    <div id="chat_list">
      <div class="chat_item" v-for="(item, index) in chat_List" :key="index">
        <!-- 我方消息 -->
        <div v-if="item.isAi != 'true'">
          <div>
            <div class="content-wrapper-right">
              <div class="chat-content-right">{{ item.content }}</div>
              <img :src="item.image" alt="" class="avator avator-right" />
            </div>
          </div>
        </div>

        <!-- 系统消息 -->
        <div v-else>
          <div>
            <div class="content-wrapper-left">
              <img
                src="https://gjc-oss.oss-cn-nanjing.aliyuncs.com/AI/logo.png"
                alt=""
                class="avator avator-left"
              />
              <div class="chat-content-left">{{ item.content }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="go" @click="goToChaK">
      <div class="share_btn">去对话</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      chat_List: [],
      id: ''
    }
  },
  created() {
    this.getAiShare()
  },
  methods: {
    //在methods里面定义一个方法
    getQueryString(variable) {
      var index = window.location.href.indexOf('?') //获取地址栏路径并进行分割
      var query = window.location.href.substr(index + 1, window.location.href.length)
      var vars = query.split('&')
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] == variable) {
          return pair[1]
        }
      }
      return false //如果没有参数就返回false 值，就不会出现上边报错的情况了
    },

    getAiShare() {
      console.log(window.location.href)
      // let url = window.location.href
      
      let id = window.location.href.split('?')[1].split('=')[1]
      console.log(id)
      axios
        .get('/mogeapi/browser/info/getAiShare', {
          params: {
            shareId: id
          }
        })
        .then((res) => {
          if (res.data.code == 0) {
            console.log(res)
            this.chat_List = res.data.data.list
          }
        })
    },
    goToChaK() {
      this.$router.push('/chaK')
    }
  }
}
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}
.container {
  height: 100vh;
  overflow-y: scroll;
  .chat_item {
    .avator-right {
      margin-left: 10px;
    }

    .avator-left {
      margin-right: 10px;
    }

    .avator {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-top: 27px;
    }

    .content-wrapper-left {
      display: flex;
      margin-left: 27px;
      justify-content: flex-start;
      color: #000000;

      .chat-content-left {
        // position: absolute;
        background: #ffffff;
        margin-top: 20px;
        display: block;
        border-radius: 5px;
        word-wrap: break-word;
        max-width: 200px;
        padding: 10px 16px 10px 10px;
        font-size: 14px;
        color: #5a5a5a;
        background-color: #f9f9f9;
      }
    }

    .content-wrapper-right {
      margin-right: 27px;
      display: flex;
      justify-content: flex-end;
      color: #ffffff;

      .time {
        height: 40px;
        color: #ffffff;
      }

      .chat-content-right {
        margin-top: 20px;
        display: block;
        border-radius: 5px;
        word-wrap: break-word;
        max-width: 200px;
        padding: 10px;
        padding: 12px 10px;
        margin-left: 10px;
        transition: all 0.2s;
        font-size: 14px;
        color: #5a5a5a;
        background-color: #f9f9f9;
      }
    }
  }
  .go {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 40px;
    margin: 0 auto;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    .share_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80%;
      height: 40px;
      background-color: #fff;
      border-radius: 30px;
      z-index: 101;
      background-color: #04e483;
      background-image: linear-gradient(90deg, #04e483 0%, #03dddc 58%, #39dd6d 100%);
      background-size: 600% 600%;
      color: #fff;
      border-radius: 60rpx;
      animation: gradientBG 10s ease infinite;
    }
    .share_btn2 {
      background-color: #04e483;
      background-image: linear-gradient(90deg, #04e483 0%, #03dddc 58%, #dd5dc8 100%);
    }
  }
  @keyframes gradientBG {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }
}
</style>
