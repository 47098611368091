<template>
  <div class="container">
    <div class="lists">
      <div class="list_item" v-for="(item, index) in goods" :key="index" @click="goTo(index)">
        <div class="list left">
          <img :src="item.img" alt="" />
          <!-- <div class="img" :style="{ background: 'url(' + item.img + ')' }"></div> -->
          <div class="text">
            <p class="p1">{{ item.p1 }}</p>
            <p class="p2">{{ item.p2 }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      goods: [
        {
          img: require('../assets/pic/7.png'),
          p1: '木鱼星球',
          p2: '木鱼一敲，烦恼全消'
        },
        // {
        //   img: require('../assets/pic/chak/01.png'),
        //   p1: '茶克浏览器',
        //   p2: '一次搜索多种结果，纯净体验'
        // },
        {
          img: require('../assets/pic/shan/shan.png'),
          p1: '闪闪弹幕',
          p2: '手机秒变LED显示屏'
        },
        {
          img: 'https://gjc-oss.oss-cn-nanjing.aliyuncs.com/takul/avator.png',
          p1: '泰裤辣',
          p2: '一次搜索多种结果，纯净体验'
        }
      ]
    }
  },
  methods: {
    goTo(e) {
      if (e == 0) {
        this.$router.push('/')
      }
      if (e == 1) {
        this.$router.push('/shan')
      }
      if (e == 2) {
        this.$router.push('/taikula')
      }
    }
  }
}
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}
a:link {
  text-decoration: none;
}
.container {
  .header {
    margin-top: 36px;
    display: flex;
    // align-items: center;
    margin-left: 5.6vw;
    .iconfont {
      font-size: 16px;
      font-weight: 600;
    }
    .header_item {
      display: flex;
      justify-content: center;
      // margin-left: 35%;
      width: 83%;
      font-size: 16px;
      font-weight: bold;
      color: #000000;
    }
  }
  .lists {
    margin-top: 30px;
    margin-left: 5.6vw;
    .list_item {
      margin-top: 10px;
      width: 89.07vw;
      height: 79px;
      background: #ffffff;
      box-shadow: 0px 2px 11px 1px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .list {
        margin-left: 2.67vw;
        display: flex;
        align-items: center;
        .text {
          margin-left: 2.67vw;
          .p1 {
            font-size: 16px;
            font-weight: 500;
            color: #000000;
          }
          .p2 {
            font-size: 13px;
            font-weight: 400;
            color: #898989;
          }
        }
        img {
          // width: 13.07vw;
          width: 51px;
          height: 51px;
          object-fit: fill; // 填充，撑满
          object-fit: contain; // 保持原比例缩小
          object-fit: cover;
          object-fit: none;
          object-fit: scale-down;
          border-radius: 11px;
        }
      }
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 18.4vw;
        height: 33px;
        margin-right: 10px;
        background: #e0f2e6;
        border-radius: 16px;
      }
    }
  }
}
</style>
